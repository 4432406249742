import React from 'react';
import {Card} from 'react-bootstrap';

const KlassebTrinn3 = () => {
    return (
        <div className="container-fluid flex p-5 cards-container">
            <Card style={{ width: '25%' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn">
              <Card.Header className="bg-primary">Grunnleggende kompetanse</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                 Etter å ha gjennomført trinn 3 skal eleven ha det grunnlaget som er nødvendig for å ha godt utbytte av avsluttende opplæring i trinn 4.
                </Card.Text>
                <Card.Text>
                 Eleven skal kjøre tydelig, sikkert og selvstendig i variert trafikk. I tillegg skal eleven kunne vurdere sin egen kompetanse til å kjøre bil i trafikk.
                </Card.Text>
              </Card.Body>
            </Card>  

            <Card style={{ width: '50%' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn animate__delay-1s">
            <Card.Header className="bg-primary">Krav</Card.Header>
            <Card.Body className="tab-content">
                <Card.Text>
                Eleven skal: <br/><br/>

                <ul>
                    <li>gjøre rede for trafikksystemet, trafikantgrupper og interessemotsetninger, og privatbilismen i et miljøperspektiv</li>
                    <li>gjøre rede for veg, vegoppmerking, skilt og annen trafikkregulering</li>
                    <li>gjøre rede for tydelig, sikker og effektiv atferd i trafikken og de regler som gjelder for bilkjøring</li>
                    <li>ha systematisk og automatisert informasjonsinnhenting</li>
                    <li>ha bestemt kjøremåte i bolig-, tettsted- og bymiljø</li>
                    <li>ha presis kjøreteknikk på høyhastighetsveg</li>
                    <li>kjøre effektivt og behagelig, miljøriktig og økonomisk i variert trafikkmiljø i god samhandling med andre trafikanter.</li>
                </ul>
                </Card.Text>
            </Card.Body>
            </Card> 

            <Card style={{ width: '25%' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn animate__delay-2s">
        <Card.Header className="bg-primary">Trinnvurdering</Card.Header>
            <Card.Body className="tab-content">
                <Card.Text>
                 Eleven skal gjennomføre sikkerhetskurs på øvingsbane i samsvar med § 11-6 i forskrift om trafikkopplæring og førerprøve, og § 11-7 i forskrift om trafikkopplæring og førerprøve. Kurset gjennomføres mot slutten av opplæringen i trinn 3.
                </Card.Text>
                <Card.Text>
                 Eleven skal ved avslutningen av trinn 3 gjennomføre obligatorisk trinnvurdering i samsvar med § 7-5 i forskrift om trafikkopplæring og førerprøve.
                </Card.Text>
            </Card.Body>
            </Card>
        </div>
    )
}

export default KlassebTrinn3