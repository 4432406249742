import React from 'react';
import {Card} from 'react-bootstrap';

const KlassebeTrinn3 = () => {
    return (
        <div className="container flex p-5 cards-container">
            <Card style={{ width: '22rem' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn">
              <Card.Header className="bg-primary">Sikker og god kjøring</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                I trinn 3 trener du på sikker og god kjøring i trafikken.
                </Card.Text>
                <Card.Text>
                I trinnets siste del må man også gjennom en obligatorisk trinnvurdering der vi  vurderer om målet for trinnet er oppnådd.
                </Card.Text>
              </Card.Body>
            </Card>  
            <Card style={{ width: '22rem' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn animate__delay-1s">
              <Card.Header className="bg-primary">Antall timer</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                 Det er ikke fastsatt noen miste antall timer for noen av trinnene.
                </Card.Text>
              </Card.Body>
            </Card> 
        </div>
    )
}

export default KlassebeTrinn3