import React from 'react';
import './loading.css';

const LoadingDots = () => {
    return (
        <div className="dots-loading">
            <div></div>
        </div>
    )
}

export default LoadingDots