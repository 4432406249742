import React from 'react';
import Layout from './Layout';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const NotFound = () => {
    return (
        <Layout>
            <div className="container heading flex">
                <h3 className="display-5">404 - Vi fant ikke denne siden</h3>
                <i className="fas fa-exclamation-triangle heading-icon"></i>
            </div>

            <div className="container page-content">
                <p className="lead">Denne siden finnes ikke på dette nettstedet.</p>
                <Link to="/"><Button className="btn btn-danger btn-lg" variant="danger">Tilbake til forsiden</Button></Link>

            </div>
            
        </Layout>
    )
}

export default NotFound
