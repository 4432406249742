import React from 'react';
import {Card} from 'react-bootstrap';

const KlassebTrinn2 = () => {
    return (
        <div className="container-fluid flex p-5 cards-container">
            <Card style={{ width: '18%' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn">
              <Card.Header className="bg-primary">Grunnleggende kompetanse</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                 Etter å ha gjennomført trinn 2 skal eleven ha det grunnlaget som er nødvendig for å ha godt utbytte av trafikal opplæring i trinn 3.
                </Card.Text>
                <Card.Text>
                 Eleven skal ha så god teknisk kjøreferdighet at oppmerksomheten kan flyttes fra eget kjøretøy til trafikkbildet og samhandlingen med andre trafikanter. I tillegg skal eleven vurdere sin egen ferdighet i å bruke bilen og oppdage mangler ved bilen..
                </Card.Text>
              </Card.Body>
            </Card>  

            <Card style={{ width: '30%' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn animate__delay-1s">
            <Card.Header className="bg-primary">Krav</Card.Header>
            <Card.Body className="tab-content">
                <Card.Text>
                Eleven skal: <br/><br/>

                <ul>
                    <li>ha kunnskap om reglene for førerkort og trafikkopplæring</li>
                    <li>ha kunnskap om bilens oppbygging med tanke på sikkerhet og miljø</li>
                    <li>ha kunnskap om eiers og brukers ansvar for kjøretøyets stand og bruk, og om økonomisk ansvar, offentlige reaksjoner og plikter ved trafikkuhell</li>
                    <li>ha kunnskap om menneskets kapasitet og trafikkens krav</li>
                    <li>kunne gjøre seg klar for kjøring</li>
                    <li>kunne sette i gang, og stanse i variert terreng</li>
                    <li>kunne gire, styre, akselerere og bremse i variert terreng</li>
                    <li>kunne rygge, vende og parkere</li>
                    <li>kunne beherske grunnleggende kjøretøybehandling i områder med liten trafikk</li>
                    <li>kunne utføre aktuell sikkerhetskontroll av bilen.</li>
                </ul>
                </Card.Text>
            </Card.Body>
            </Card> 

            <Card style={{ width: '30%' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn animate__delay-2s">
        <Card.Header className="bg-primary">...</Card.Header>
            <Card.Body className="tab-content">
                <Card.Text>
                Eleven skal: <br/><br/>

                <ul>
                    <li>ha kunnskap om reglene for førerkort og trafikkopplæring</li>
                    <li>ha kunnskap om bilens oppbygging med tanke på sikkerhet og miljø</li>
                    <li>ha kunnskap om eiers og brukers ansvar for kjøretøyets stand og bruk, og om økonomisk ansvar, offentlige reaksjoner og plikter ved trafikkuhell</li>
                    <li>ha kunnskap om menneskets kapasitet og trafikkens krav</li>
                    <li>kunne gjøre seg klar for kjøring</li>
                    <li>kunne sette i gang, og stanse i variert terreng</li>
                    <li>kunne gire, styre, akselerere og bremse i variert terreng</li>
                    <li>kunne rygge, vende og parkere</li>
                    <li>kunne beherske grunnleggende kjøretøybehandling i områder med liten trafikk</li>
                    <li>kunne utføre aktuell sikkerhetskontroll av bilen.</li>
                </ul>
                </Card.Text>
            </Card.Body>
            </Card>

            <Card style={{ width: '18%' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn animate__delay-3s">
              <Card.Header className="bg-primary">Trinnvurdering</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                Eleven skal ved avslutningen av trinn 2 gjennomføre trinnvurdering i samsvar med
                § 7-5 i forskrift om trafikkopplæring og førerprøve.
                </Card.Text>
                
              </Card.Body>
            </Card>
        </div>
    )
}

export default KlassebTrinn2