import React from 'react';
import {Card} from 'react-bootstrap';

const KlassebeTrinn2 = () => {
    return (
        <div className="container flex p-5 cards-container">
            <Card style={{ width: '22rem' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn">
              <Card.Header className="bg-primary">Beherske bil og tilhenger</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                    Trinn 2, går ut på teknisk trening i å beherske bil og tilhenger.
                </Card.Text>
                <Card.Text>
                Man skal kunne koble av og på tilhenger og utføre sikkerhetskontroll av vogntoget.
                </Card.Text>
              </Card.Body>
            </Card>  
            <Card style={{ width: '22rem' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn animate__delay-1s">
              <Card.Header className="bg-primary">Trinnvurdering</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                    Trinn 2 har og en obligatorisk trinnvurdering for å kunne vurdere om målet for trinnet oppnådd.
                </Card.Text>
              </Card.Body>
            </Card> 
        </div>
    )
}

export default KlassebeTrinn2