import React, { useState, useEffect, useReducer } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import StateContext from './StateContext'; // Importing our React context. One for subscribing to all state changes
import DispatchContext from './DispatchContext';  // And one for subscribing to the dispatch in order to change and subscribe to individual state items

import ScrollToTop from "../src/ScrollToTop";
import Klassebpage from './components/Klassebpage';
import Klassebepage from './components/Klassebepage';
import Tkgpage from './components/Tkgpage';
import Home from './components/Home';
import Pricepage from './components/Pricepage';
import Kurs from './components/Kurs';
import Kontakt from './components/Kontakt';
import Datalagring from './components/Datalagring';
import LoadingDots from './components/loading/LoadingDots';
import NotFound from './components/NotFound';

import Axios from 'axios';

function App() {

  // Local dev
  // Axios.defaults.baseURL = 'http://192.168.0.101:1337/';
  // Platform.sh - Deployment
  // Axios.defaults.baseURL = 'https://www.master-7rqtwti-h4apbndpyn7v4.eu-2.platformsh.site/';

  // DigitalOcean hosted - With custom Domain
  Axios.defaults.baseURL = 'https://kambenit.no/';
  // Axios.defaults.baseURL = 'https://134.209.139.211/';
  
  // Stores the results from calling our API 
  const [priser, setPriser] = useState({});
  const [tkg, setTkg] = useState([]);
  const [frontpage, setFrontpage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [subsites, setSubsites] = useState([]);

  const initialState = {
    isLoading: false,
    isUpdated: 0,
    kontaktInfo: {}
  }

  const ourReducer = (state,action) => {
    switch (action.type) {
      case "isLoading":
        return {...state, isLoading: true}; 
      case "isDoneLoading":
          return {...state, isLoading: false}; 
      case "isUpdated":
          return {...state, isUpdated: state.isUpdated+1};
      case "subsitesUpdated":
          return{...state, kontaktInfo: subsites }
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(ourReducer, initialState);
  
  useEffect(() => {
    const source = Axios.CancelToken.source()
      async function fetchData() {
          setIsLoading(true);
          try {
              const response = await Axios({
                  method: 'get',
                  url: 'priser',
                  cancelToken: source.token,
              });
              setPriser(response.data);
          } catch (e) {
              console.log("Response Error: ");
              console.log(e.response.data);
              // setfetchError(true);
          }
          try {
            const response2 = await Axios({
                method: 'get',
                url: 'tkg',
                cancelToken: source.token,
            });
            setTkg(response2.data);
          } catch (e) {
              console.log("Response Error: ");
              console.log(e.response2.data);
              // setfetchError(true);
          }
          try {
            const response3 = await Axios({
                method: 'get',
                url: 'forside',
                cancelToken: source.token,
            });
            setFrontpage(response3.data);
          } catch (e) {
              console.log("Response Error: ");
              console.log(e.response3.data);
              // setfetchError(true);
          }
          try {
            const response4 = await Axios({
                method: 'get',
                url: 'subsites',
                cancelToken: source.token,
            });
            setSubsites(response4.data);
            dispatch({type: "subsitesUpdated"})
            
          } catch (e) {
              console.log("Response Error: ");
              console.log(e.response.data);
              // setfetchError(true);
          }
          setIsLoading(false);
      }

      fetchData();

      return () => {
        source.cancel()
    }
  }, [])

  if (isLoading) {
    return <LoadingDots>Loading ...</LoadingDots>;
  }

  const formatDate = (date) => {
    let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
    }

  const dateNowFormated = formatDate(Date());
  
  const fbPostId = frontpage.FBPost;

  const tkgPris = priser.TKG;
  const isKampanje = priser.TKG_Kampanje;
  const tkgRabatt = priser.TKG_Rabatt_Kr;
  const tkgKampanje = tkgPris - priser.TKG_Rabatt_Kr;
  const tkgKampanjeSlutt = priser.Kampanje_Slutt;

  const tgkDate = new Date(tkg.Dato);
  const tgkDateMonthName = tgkDate.toLocaleString('no-NO', { month: 'long' });
  const tgkDateFormated = `${tgkDate.getDate()}. ${tgkDateMonthName}`;
  const tgkTime = tkg.Tidspunkt;

  return ( 
    <>  
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <Router>
          <ScrollToTop />
          <Switch>
              <Route exact path="/">
                <Home tgkDate={tgkDate} tgkDateMonthName={tgkDateMonthName} tgkDateFormated={tgkDateFormated} tgkTime={tgkTime} isKampanje={isKampanje} tkgRabatt={tkgRabatt} tkgPris={tkgPris}
                      tkgKampanjeSlutt={tkgKampanjeSlutt} dateNowFormated={dateNowFormated} fbPostId={fbPostId} frontpage={frontpage} tkg={tkg}
                />
              </Route>
              <Route exact path="/klasse-b"><Klassebpage tkgPris={tkgPris} tkgRabatt={tkgRabatt}/></Route>
                <Route exact path="/kjoretimer"><Klassebpage tkgPris={tkgPris}/></Route>
              <Route exact path="/klasse-be"><Klassebepage /></Route>
              <Route exact path="/trafikalt-grunnkurs"><Tkgpage tkgPris={tkgPris} tgkDateFormated={tgkDateFormated} tgkTime={tgkTime}/></Route>
              <Route exact path="/priser"><Pricepage tkgPris={tkgPris} isKampanje={isKampanje} tkgKampanje={tkgKampanje} priser={priser}/></Route>
              <Route exact path="/vare-kurs"><Kurs /></Route>
              <Route exact path="/kontakt"><Kontakt /></Route>
              <Route exact path="/datalagring" component={Datalagring}></Route>
                
              <Route exact path="/proff-admin" render={() => (window.location = "https://proff-backend.netlify.app/")} />

              <Redirect from="/morkekjoring" exact to="/trafikalt-grunnkurs" />
              <Redirect from="/om-oss" exact to="/kontakt" />
              <Redirect from="/bilene" exact to="/" />
              <Redirect from="/priser-klasse-b" exact to="/priser" />
              <Redirect from="/pakkepriser" exact to="/priser" />
              <Redirect from="/priser-klasse-be" exact to="/priser" />
              <Redirect from="/priser-tgk" exact to="/priser" />

              <Route component={NotFound} />
          </Switch>
        </Router>
      </DispatchContext.Provider>
    </StateContext.Provider>

      <div className="App"></div>
    </>
  );
}

export default App;
