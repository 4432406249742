import React, {useState} from 'react';


const KlassebeTabs = (props) => {

    // Using states to change classes on the tabs
    const [active1, setActive1] = useState('service-button-active');  
    const [active2, setActive2] = useState(''); 
    const [active3, setActive3] = useState(''); 
    const [active4, setActive4] = useState(''); 

    // Calling this function on click of the different tabs.
    const setTab = (tab) => {
      if (tab === 4) {
        props.setKlassebeTab(4);
        setActive4('service-button-active');

        setActive1('');
        setActive2('');
        setActive3('');
      } else if (tab === 3) {
        props.setKlassebeTab(3);
        setActive3('service-button-active');

        setActive1('');
        setActive2('');
        setActive4('');
      } else if (tab === 2) {
        props.setKlassebeTab(2);
        
        setActive2('service-button-active');

        setActive1('');
        setActive3('');
        setActive4('');
      } else {
        props.setKlassebeTab(1);
        setActive1('service-button-active');

        setActive2('');
        setActive3('');
        setActive4('');
      }
    }
    return ( <>
        <div className="container-fluid service-selector">
          <div className="container service-buttons">
            <div onClick={() => {setTab(1)}} className={`service-button ${active1}`} type="button" data-toggle="collapse" data-target="#collapseKlasseb" aria-expanded="false" aria-controls="collapseKlasseb">
            <i className="fas fa-chalkboard-teacher service-icon"></i> Trinn 1 <br />
            </div>
            <div onClick={() => {setTab(2)}} className={`service-button ${active2}`} type="button" data-toggle="collapse" data-target="#collapseKlassebe" aria-expanded="false" aria-controls="collapseKlassebe">
            <i className="fas fa-car service-icon"></i> Trinn 2
            </div>
            <div onClick={() => {setTab(3)}} className={`service-button ${active3}`} type="button" data-toggle="collapse" data-target="#collapseKlassebe" aria-expanded="false" aria-controls="collapseKlassebe">
            <i className="fas fa-car-side service-icon"></i> Trinn 3
            </div>
            <div onClick={() => {setTab(4)}} className={`service-button ${active4}`} type="button" data-toggle="collapse" data-target="#collapseKlassebe" aria-expanded="false" aria-controls="collapseKlassebe">
            <i class="fas fa-user-check service-icon"></i> Trinn 4
            </div>
          </div>
        </div>
         
        </>
    )
}

export default KlassebeTabs
