import React, {useState, useContext} from 'react';
import Layout from './Layout';
import TkgTabs from './tkg/TkgTabs';
import TkgDager from './tkg/TkgDager';
import TkgKurs from './tkg/TkgKurs';

import StateContext from '../StateContext';


const Tkgpage = (props) => {

    const appContext = useContext(StateContext);
    const textInfo = appContext.kontaktInfo;

    const [tab, setTab] = useState(1);
    const [tabCSS, setTabCss] = useState('tab-tkg-1');
  
    const setTkgTab = (value) => {
        setTab(value);
        setTabCss(`tab-tkg-${value}`);
    }

    function createTgkInfo() {
        return {__html: textInfo.TgkInfo};
    }

    return (
        <Layout>
            <div className="container heading flex">
                <h3 className="display-5">Trafikalt Grunnkurs</h3>
                <i className="fas fa-chalkboard-teacher heading-icon"></i>
            </div>

            <div className="container page-content">
                <h3 className="display-6"><strong>{textInfo.TgkHeading}</strong></h3>
                <p className="lead" dangerouslySetInnerHTML={createTgkInfo()}></p>
                <br/>
            </div>
                <TkgTabs setTkgTab={(value) => {setTkgTab(value)}}/>
                <section className={`container-fluid ${tabCSS}`}>
                    {tab === 2 ? (<TkgKurs tkgPris={props.tkgPris} tgkDateFormated={props.tgkDateFormated} tgkTime={props.tgkTime}/> ) : (<TkgDager />) }
                </section>
            
        </Layout>
    )
}

export default Tkgpage
