import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Layout from './Layout';
import Main from './Main';
import ServiceSelector from './ServiceSelector';
import Klasseb from './Klasseb';
import KlasseBE from './KlasseBE';
import Tkg from './Tkg';
import FacebookSection from './FacebookSection';

import CookieConsent, { Cookies } from "react-cookie-consent";

const Home = (props) => {

    const tkg = props.tkg;
    const frontpage = props.frontpage;

    const [service, setService] = useState('b');
  
    const setServiceType = (value) => {
        setService(value);
    }

    return (
        <Layout>
          <Main tgkDate={props.tgkDate} tgkDateMonthName={props.tgkDateMonthName} tgkDateFormated={props.tgkDateFormated} tgkTime={props.tgkTime} isKampanje={props.isKampanje} 
                tkgRabatt={props.tkgRabatt} tkgPris={props.tkgPris} tkgKampanjeSlutt={props.tkgKampanjeSlutt} dateNowFormated={props.dateNowFormated} tkg={tkg} frontpage={frontpage}>
          </Main> 
          <ServiceSelector setServiceType={(value) => {setServiceType(value)}}/>
          <section className="container-fluid services">
            { service === 'tkg' ? (<Tkg /> ) : service === 'be' ? (<KlasseBE />) : (<Klasseb />) }
          </section>
          <FacebookSection fbPostId={props.fbPostId} />
          <CookieConsent
            buttonText="Jeg forstår" 
            style={{ background: "#232629", width: "20%" }}  
            buttonStyle={{backgroundColor: "#28A745", color: "#fff", fontSize: "13px" }}
            acceptOnScroll={true}
            acceptOnScrollPercentage={70}
            onAccept={() => {
              console.log(`Consent given. Cookie: ${Cookies}`);
            }}
            >
            Vi bruker noen få cookies for å kunne levere noe av funksjonaliteten på siden. Du kan lese mer om dette <Link to="/datalagring" target="new"><strong>her</strong></Link>.
          </CookieConsent>
        </Layout>
    )
}

export default Home
