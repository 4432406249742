import React from 'react';
import Layout from './Layout';

const Datalagring = () => {
    return (
        <Layout>
            <div className="container heading flex">
                <h3 className="display-5">Datalagring / Privacy Policy</h3>
                <i class="far fa-save heading-icon"></i>
            </div>

            <div className="container page-content">
                <p>Denne siden lagrer noen få informasjonskapsler (cookies) for å kunne levere noe av funksjonaliteten på siden. <br/>
                <strong>Informasjon om disse:</strong><br/><br/>
                    <ul>
                        <li>Facebook Tracking cookie: <br/>
                         Overføres automatisk fra Facebook da siden benytter Facebook Page feed. Denne sjekker eks. om du har likt Facebook siden til Proff Trafikkskole tidligere.</li>
                         <li>Google: <br/>
                         Et sett av cookies overføres automatisk fra Google da vi bruker Google Maps for å vise vår lokasjon, og Google Anlytics for å se statestikk for sidebesk ol.
                         </li>
                         <li>TABS: <br/>
                         Cookies fra tabs.no på grunn av at vi har innhold fra denne eksterne siden under vår kursside.  
                         </li>
                    </ul>
                </p>
                <p>Denne siden lagrer ingen informasjon om deg som person. Den har heller ingen innlogging eller skjemautfylling der informasjon om deg kreves. Elev innlogging som
                    er lenket til fra toppen av siden sender deg til en ekstern leverandør, tabs.no. TABS håndterer deretter din innlogging og behandler eventuelle personopplysninger. 
                    Les mer om personvern for TABS <a href="https://tabselev.no/personvern" target="new" title="Gå til tabs.no">her</a>.
                </p>
                <p><strong><i>Ved å bruke denne nettsiden samtykker du til at vi kan sette disse informasjonkapslene i din nettleser. Dersom du ønsker å fjerne disse informasjonskapslene
                    kan du administrere dette lokalt i din nettleser. Les mer om dette her: <a href="https://nettvett.no/slik-administrer-du-informasjonskapsler/" target="new">
                    https://nettvett.no/slik-administrer-du-informasjonskapsler/</a>
                    </i></strong></p>

            </div>
            
        </Layout>
    )
}

export default Datalagring
