import React from 'react';
import { FacebookProvider, EmbeddedPost } from 'react-facebook';
import Like from '../like.png'

const FacebookSection = (props) => {

    const fbPostUrl = `https://www.facebook.com/ProffTrafikkskole/posts/${props.fbPostId}`
    
    return ( <>
        <div className="container-fluid bg-blue">
            <div className="container fb-heading">
                <h2 className="display-5">Følg oss på Facebook!</h2> 
                <div className="ml-2 animate__animated animate__bounceInLeft animate__delay-2s">
                <a href="https://www.facebook.com/ProffTrafikkskole" target="new" title="Gå til Facebook siden"><img style={{ width: "50px"}} src={Like} alt="Facebook like button"/></a>
            </div>
            </div>
        </div>
        <div className="container-fluid flex facebook-section hide-from-360px">
            
            <div className="container flex fb-page">
                <div className="fb-post-wrapper">

                <FacebookProvider appId="2717577451898381">
                    <EmbeddedPost href={fbPostUrl}  />
                </FacebookProvider>
                    {/* <div className="fb-post" data-href={fbPostUrl}
                        data-show-text="true">
                    </div> */}

                </div>
            </div>
        </div>
    </>)
}

export default FacebookSection
