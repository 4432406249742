import React, {useState} from 'react';


const ServiceSelector = (props) => {

    // Using states to change classes on the tabs
    const [activeB, setActiveB] = useState('service-button-active');  
    const [activeBe, setActiveBe] = useState(''); 
    const [activeTkg, setActiveTkg] = useState(''); 

    // Calling this function on click of the different tabs. Sets service type in App.js state trough props. And sets and removes local state to manipulate tab classe
    const changeServiceTab = (type) => {
      if (type === 'be') {
        props.setServiceType('be');
        setActiveBe('service-button-active');

        setActiveB('');
        setActiveTkg('');
      } else if (type === 'tkg') {
        props.setServiceType('tkg');
        setActiveTkg('service-button-active');

        setActiveB('');
        setActiveBe('');
      } else {
        props.setServiceType('b');
        setActiveB('service-button-active');

        setActiveBe('');
        setActiveTkg('');
      }
    }
    return ( <>
        <div className="container-fluid service-selector">
          <div className="container service-buttons">
            <div onClick={() => {changeServiceTab('b')}} className={`service-button ${activeB}`} type="button" data-toggle="collapse" data-target="#collapseKlasseb" aria-expanded="false" aria-controls="collapseKlasseb">
              <i className="fas fa-car service-icon"></i> Klasse B <br />
            </div>
            <div onClick={() => {changeServiceTab('be')}} className={`service-button ${activeBe}`} type="button" data-toggle="collapse" data-target="#collapseKlassebe" aria-expanded="false" aria-controls="collapseKlassebe">
              <i className="fas fa-caravan service-icon"></i> Klasse BE
            </div>
            <div onClick={() => {changeServiceTab('tkg')}} className={`service-button ${activeTkg}`} type="button" data-toggle="collapse" data-target="#collapseKlassebe" aria-expanded="false" aria-controls="collapseKlassebe">
              <i className="fas fa-chalkboard-teacher service-icon"></i> TGK
            </div>
          </div>
        </div>
         
        </>
    )
}

export default ServiceSelector
