import React from 'react';
import {Card} from 'react-bootstrap';

const TkgDager = () => {
    return (
        <div className="container-fluid flex p-5 cards-container">
            <Card style={{ width: '18%' }} className="mb-2 ml-4 bg-light animate__animated animate__fadeIn">
              <Card.Header className="bg-primary"><i class="fas fa-info-circle"></i> Dag 1</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                Dag 1 gir deg en introduksjon av kurset og kjøreskolen.
                </Card.Text>
                <Card.Text>
                Den tar for seg trinn 1.1 i læreplan klasse B som omhandler trafikkopplæringen.
                </Card.Text>
              </Card.Body>
            </Card>  

            <Card style={{ width: '18%' }} className="mb-2 ml-4 bg-light animate__animated animate__fadeIn animate__delay-1s">
            <Card.Header className="bg-primary"><i class="fas fa-traffic-light"></i> Dag 2</Card.Header>
            <Card.Body className="tab-content">
                <Card.Text>
                Dag 2 tar for seg trinn 1.2 Grunnleggende forståelse for trafikk.
                </Card.Text>
            </Card.Body>
            </Card> 

            <Card style={{ width: '18%' }} className="mb-2 ml-4 bg-light animate__animated animate__fadeIn animate__delay-2s">
        <Card.Header className="bg-primary"><i class="fas fa-car"></i> Dag 3</Card.Header>
            <Card.Body className="tab-content">
                <Card.Text>
                Dag 3 tar for seg trinn 1.3 Mennesket i trafikken
                </Card.Text>
                <Card.Text>
                Samt trinn 1.4 Øvingskjøring og kjøreerfaring.
                </Card.Text>
            </Card.Body>
            </Card>

            <Card style={{ width: '18%' }} className="mb-2 bg-light ml-4 animate__animated animate__fadeIn animate__delay-3s">
              <Card.Header className="bg-primary"><i class="fas fa-first-aid"></i> Dag 4</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                Dag 4 omhandler trinn 1.5 Tiltak ved trafikkulykker, og 1.6 Førstehjelp.
                </Card.Text>
                
              </Card.Body>
            </Card>

            <Card style={{ width: '22%' }} className="mb-2 bg-light ml-4 animate__animated animate__fadeIn animate__delay-4s">
              <Card.Header className="bg-primary"><i class="fas fa-moon"></i> Dag 5</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                Dag 5 tar for seg trinn 1.7 Trafikant i mørket. 
                </Card.Text>
                <Card.Text>
                Trinnet består av praktiske demonstrasjoner i bil i reelle trafikksituasjoner der trafikklæreren kjører, du som elev er passasjer.
                </Card.Text>
                
              </Card.Body>
            </Card>
        </div>
    )
}

export default TkgDager