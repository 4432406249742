import React from 'react';
import Layout from './Layout';

const Kurs = () => {
    return (
        <Layout>
            <div className="container heading flex">
                <h3 className="display-5">Våre kurs</h3>
                <i className="fas fa-chalkboard-teacher heading-icon"></i>
            </div>

            <div className="container page-content">
                <div className="responsive-iframe">
                    <iframe title="kursoversikt" src="https://profftrafikkskoleda.tabs.no/kursoversikt" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen></iframe>
                </div>
            </div>
            
        </Layout>
    )
}

export default Kurs
