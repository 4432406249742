import React from 'react';
import {Link} from 'react-router-dom';
import {Card, Button} from 'react-bootstrap';
import be from '../../be.png';

const KlassebeTrinn = (props) => {
    return (
        <div className="container flex p-5 cards-container">
            <Card style={{ width: '22rem' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn">
              <Card.Header className="bg-primary">Klassse BE/B96</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                  Før du kan ta klasse B96/BE, må du ha førerkort klasse B
                </Card.Text>
                <Card.Text>
                  Du kan lese mer om kjøreopplæringen for klasse B på våre sider.
                </Card.Text>
                <Link to="/klasse-b"><Button variant="danger">Les om Klasse B</Button></Link>
              </Card.Body>
            </Card>  

            <Card bg="danger" style={{ width: '22rem' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn animate__delay-1s">
              
            <Card.Img variant="top" src={be} />
              <Card.Body className="image-card-body tab-content">
              Med førerkort klasse BE kan du kjøre med stor henger.
              </Card.Body>
            </Card> 
        </div>
    )
}

export default KlassebeTrinn