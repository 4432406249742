import React, {useState, useContext} from 'react'
import Layout from './Layout'
import KlassebeTabs from './klassebe/KlassebeTabs';
import KlassebeTrinn from './klassebe/KlassebeTrinn';
import KlassebeTrinn2 from './klassebe/KlassebeTrinn2';
import KlassebeTrinn3 from './klassebe/KlassebeTrinn3';
import KlassebeTrinn4 from './klassebe/KlassebeTrinn4';

import StateContext from '../StateContext';

const Klassebepage = () => {

    const appContext = useContext(StateContext);
    const textInfo = appContext.kontaktInfo;

    const [tab, setTab] = useState(1);
    const [tabCSS, setTabCss] = useState('tab-be-1');
  
    const setKlassebeTab = (value) => {
        setTab(value);
        setTabCss(`tab-be-${value}`);
    }

    function createKlassebeInfo() {
        return {__html: textInfo.KlassebeInfo};
      }

    return (
        <Layout>
            <div className="container heading flex">
                <h3 className="display-5">Klasse BE/B96</h3>
                <i className="fas fa-trailer heading-icon"></i>
            </div>

            <div className="container page-content">
                <h3 className="display-6"><strong>{textInfo.KlassebeHeading}</strong></h3>
                <p className="lead" dangerouslySetInnerHTML={createKlassebeInfo()}></p>
                <br/>
                <h3 className="display-6">
                    <strong>Opplæringen er delt opp i 4 deler:</strong>
                </h3>
            </div>
                <KlassebeTabs setKlassebeTab={(value) => {setKlassebeTab(value)}}/>
                <section className={`container-fluid ${tabCSS}`}>
                    {tab === 4 ? (<KlassebeTrinn4 /> ) :  tab === 3 ? (<KlassebeTrinn3 /> ) : tab === 2 ? (<KlassebeTrinn2 />) : (<KlassebeTrinn />) }
                </section>
            
        </Layout>
    )
}

export default Klassebepage
