import React from 'react';
import {Card, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const KlassebTrinn = (props) => {
    return (
        <div className="container flex p-5 cards-container">
            <Card style={{ width: '42rem' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn">
            {/* <Card.Img variant="top" src={be} /> */}
              <Card.Header className="bg-primary">Trafikalt Grunnkurs</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                  Trafikalt grunnkurs må være gjennomført før du kan begynne å øvelseskjøre privat, og ta kjøretimer. 
                  Kurset strekker seg over 4 kvelder i sommerhalvåret og 5 på vinteren da Trafikant i mørket er en del av kurset.
                </Card.Text>
                <Card.Text>
                  Vi holder kursene i egne trivelige lokaler på Abildsø i Oslo.
                </Card.Text>
                <Link to="/trafikalt-grunnkurs"><Button variant="danger">Les mer om TGK</Button></Link>
              </Card.Body>
            </Card>  

            <Card style={{ width: '24rem' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn animate__delay-1s">
            <Card.Header className="bg-primary">Priser og påmelding</Card.Header>
            <Card.Body className="tab-content">
                <Card.Text>
                Trafikalt grunnkurs inkl. førstehjelp: {props.TkgPris - props.tkgRabatt} Kr <br/>
                </Card.Text>
                <Card.Text>
                Ta første trinn og meld deg på kurs her: <br/>
                </Card.Text>
                <Link to="/vare-kurs"><Button variant="danger">Påmelding</Button></Link>
            </Card.Body>
            </Card> 
        </div>
    )
}

export default KlassebTrinn