import React from 'react';
import {Card, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import tkg from '../tkg.jpg';

const Tkg = () => {
    return (
        <div className="container flex p-5 cards-container">
            <Card bg="danger" style={{ width: '22rem' }} className="ml-4 mt-2 animate__animated animate__fadeIn tkg-card-01">
            {/* <Card.Img variant="top" src={be} /> */}
              <Card.Header>Trafikalt grunnkurs</Card.Header>
              <Card.Body>
                <Card.Text>
                  Pålagt grunnkurs for klasse B, BE og Moped. Kurset må gjennomføres før øvelseskjøring både privat og ved trafikkskole kan starte.
                </Card.Text>
                <Card.Text>
                  Kurset strekker seg over 4-5 kvelder.
                </Card.Text>
                <Link to="/trafikalt-grunnkurs"><Button variant="light">Les mer</Button></Link>
              </Card.Body>
            </Card>  

            <Card bg="danger" style={{ width: '22rem' }} className="ml-4 mt-2 animate__animated animate__fadeIn animate__delay-1s tkg-card-02">
            <Card.Header>Påmelding og priser</Card.Header>
            <Card.Body>
            <Card.Text>
                Prisliste for TGK finner du her: <br/>
                <Link to="/priser"><Button variant="light">Priser</Button> <br/></Link> <br/>
            </Card.Text>
            <Card.Text>
                Bestill plass på neste TGK: <br/>
                <Link to="/vare-kurs"><Button variant="light">Påmelding</Button></Link>
            </Card.Text>
            </Card.Body>
            </Card>
            
            {/* <img className="ml-4" src={be} alt="" /> */}
            <Card bg="danger" style={{ width: '22rem' }} className="ml-4 mt-2 animate__animated animate__fadeIn animate__delay-2s tkg-card-03">
            <Card.Img variant="top" src={tkg} />
              <Card.Body>
              Er du fylt 25 år trenger du kun førstehjelpskvelden og Trafikant i mørket før oppkjøring.
              </Card.Body>
            </Card>  
        </div>
    )
}

export default Tkg
