import React, {useState, useContext} from 'react';
import logo from '../logo.png';
import {Link} from 'react-router-dom';

import StateContext from '../StateContext';

const Footer = () => {
    // Useing state to set css class to display scroll button when the page is scrolled
    const [displayScroll, setDisplayScroll] = useState('hide-scroll-top');
    // Year variable used for the copyright
    const thisYear = new Date().getFullYear();

    window.onscroll = function() {scrollFunction()};
    function scrollFunction() {
    if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        setDisplayScroll('scroll-top');
    } else {
        setDisplayScroll('hide-scroll-top');
    }
    }

    const appContext = useContext(StateContext);
    const kontaktInfo = appContext.kontaktInfo;

    return (
        <div>
            <div id="toTop" className={displayScroll}>
            <a href="#top" className="scroll" aria-label="Scroll to Top"><i className="fas fa-arrow-up"></i></a>
            </div>

            <div className="container-fluid mb-3 border-top-1 pt-1">
                <div className="container flex footer-section">
                    <div className="footer-area mb-2">
                        <a href="#top"><img src={logo} alt="Logo" /></a>
                        <div className="copy-area">
                        © {thisYear} Proff Trafikkskole {' '}{' '} | <Link to="/datalagring" target="new">Datalagring</Link>
                        </div>
                    </div>
                    <div className="footer-area">
                        <p className="lead">
                            Kontakt Oss
                        </p>
                        <p>
                            Telefon: <br/>
                            <a href={`tel:+47${kontaktInfo.Phone}`}>{kontaktInfo.Phone}</a>
                        </p>
                        <p>
                            Epost: <br/>
                            <a href={`mailto:${kontaktInfo.Email}`}>{kontaktInfo.Email}</a> 
                        </p>
                        <p>
                            Adresse: <br/>
                            <a target="new" href="https://goo.gl/maps/EaWzkvV6QFePobA37">{kontaktInfo.Address}</a> 
                        </p>
                    </div>
                    <div className="footer-area">
                        <p className="lead">
                            Her finner du oss
                        </p>
                        <iframe title="Proff" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2002.222632351778!2d10.824516116109725!3d59.878653173898186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416f411ac987cb%3A0x2c5edc19d9b63a1f!2sEnebakkveien%20275%2C%201187%20Oslo!5e0!3m2!1sno!2sno!4v1598813351983!5m2!1sno!2sno" width="300" height="200" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                    </div>
                </div>
            </div>
            <span className="proff-bottom-line"></span>
        </div>
    )
}

export default Footer
