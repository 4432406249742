import React, {useState} from 'react'
import Layout from './Layout'
import {Table, Button} from 'react-bootstrap';

const Pricepage = (props) => {

    const priser = props.priser; 
    const eveningRatePrice = 100;

    const [table, setTable] = useState('tkg');
    const [eveningRate, setEveningRate] = useState(false);
    const [pakkepriser, setPakkepriser] = useState(false);
    const [pakkepriserAut, setPakkepriserAut] = useState(false);

    

    const changePriceTable = (table) => {
        setEveningRate(false);
        setTable(table);
        setPakkepriser(false);
        setPakkepriserAut(false);
        
    }

    const toogleEveningRate = () => {
        if(eveningRate) {
            setEveningRate(false);
        } else {
            setEveningRate(true);
        }
    }
    const tooglePakkepriser = () => {
        if(pakkepriser) {
            setPakkepriser(false);
        } else {
            setPakkepriser(true);
        }
    }
    const tooglePakkepriserAut = () => {
        if(pakkepriserAut) {
            setPakkepriserAut(false);
        } else {
            setPakkepriserAut(true);
        }
    }
    

    return (
        <Layout>
            <div className="container heading flex">
                <h3 className="display-5">Priser</h3>
                <i className="far fa-list-alt heading-icon"></i>
            </div>
            <div className="container page-content">
            <p className="lead">
                Under finner du våre for tiden gjeldene priser for trafikkopplæringen. Velg type opplæring/kriterier med knappene for å se aktuelle priser.
            </p>
            <Button onClick={(table) => changePriceTable('tkg')} variant={table === 'tkg' ? "danger" : "outline-danger"}>TGK</Button>{' '}
            <Button onClick={(table) => changePriceTable('b')} variant={table === 'b' ? "danger" : "outline-danger"}>Klasse B</Button>{' '}
            <Button onClick={(table) => changePriceTable('baut')} variant={table === 'baut' ? "danger" : "outline-danger"}>Klasse B Aut.</Button>{' '}
            <Button onClick={(table) => changePriceTable('be')} variant={table === 'be' ? "danger" : "outline-danger"}>Klasse BE/B96</Button>{' '}
            {table !== 'tkg' ? (
            <div className="custom-control custom-switch">
            <input disabled={pakkepriser} onClick={() => toogleEveningRate()} type="checkbox" checked={eveningRate} className="custom-control-input" id="customSwitches" />
            <label className="custom-control-label" htmlFor="customSwitches">Slå på for kveldspriser (Etter 16:00)</label>
            </div>) : (<br/>)}
            {table === 'b' ? (
            <div className="custom-control custom-switch">
            <input onClick={() => tooglePakkepriser()} type="checkbox" checked={pakkepriser} className="custom-control-input" id="customSwitches2" />
            <label className="custom-control-label" htmlFor="customSwitches2">Slå på for endre til pakkepriser</label>
            </div>) : (<></>)}

                {pakkepriser ? (
                    <Table striped bordered hover className="price-table">
                    <thead>
                        <tr>
                        <th>Tjeneste</th>
                        <th>Pris</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td><strong>Full Pakke</strong></td>
                        <td></td>
                        
                        </tr>
                        <tr>
                        <td>8 kjøretimer à kr. {priser.Kjoretime}</td>
                        <td>{priser.Kjoretime * 8} kr</td>
                        
                        </tr>
                        <tr>
                        <td>2 Trinnvurderinger à kr. {priser.Trinnvurdering}</td>
                        <td>{priser.Trinnvurdering * 2} kr</td>
                        </tr>
                        <tr>
                        <td>Sikkerhetskurs på øvingsbane eksl. baneleie</td>
                        <td>{priser.SikkerhetskursBane} kr</td>
                        </tr>
                        <tr>
                        <td>Sikkerhetskurs på vei</td>
                        <td>{priser.SikkerhetskursVei} kr</td>
                        </tr>
                        <tr>
                        <td>Førerprøve</td>
                        <td>{priser.Forerprove} kr</td>
                        </tr>
                        <tr>
                        <td><strong>Pakkepris (Du sparer kr. {(priser.Kjoretime * 8) + (priser.Trinnvurdering * 2) + priser.SikkerhetskursBane + priser.Forerprove + priser.SikkerhetskursVei - priser.FullPakke},-)</strong></td>
                        <td><strong>{priser.FullPakke} kr</strong></td>
                        </tr>
                        <tr>
                        <td></td>
                        <td></td>
                        </tr>
                        <tr>
                        <td><strong>Obligatorisk pakke</strong></td>
                        <td></td>
                        </tr>
                        <tr>
                        <td>2 Trinnvurderinger à kr. {priser.Trinnvurdering}</td>
                        <td>{priser.Trinnvurdering * 2} kr</td>
                        </tr>
                        <tr>
                        <td>Sikkerhetskurs på vei</td>
                        <td>{priser.SikkerhetskursVei} kr</td>
                        </tr>
                        <tr>
                        <td>Sikkerhetskurs på øvingsbane eksl. baneleie</td>
                        <td>{priser.SikkerhetskursBane} kr</td>
                        </tr>
                        <tr>
                        <td><strong>Pakkepris (du sparer kr. {(priser.Trinnvurdering * 2) + priser.SikkerhetskursVei + priser.SikkerhetskursBane - priser.ObligatoriskPakke},-)</strong></td>
                        <td><strong>{priser.ObligatoriskPakke} kr</strong></td>
                        </tr>
                        <tr>
                        <td></td>
                        <td></td>
                        </tr>
                        <tr>
                        <td><strong>Kjøretimepakke</strong></td>
                        <td></td>
                        </tr>
                        <tr>
                        <td>10 kjøretimer à kr {priser.Kjoretime}</td>
                        <td>{priser.Kjoretime * 10} kr</td>
                        </tr>
                        <tr>
                        <td><strong>Pakkepris (du sparer kr. {priser.Kjoretime * 10 - priser.KjoretimePakke},-)</strong></td>
                        <td><strong>{priser.KjoretimePakke} kr</strong></td>
                        </tr>
                    </tbody>
                </Table>
                )
                : 
                table === 'baut' ? (
                    <div className="custom-control custom-switch">
                    <input onClick={() => tooglePakkepriserAut()} type="checkbox" checked={pakkepriserAut} className="custom-control-input" id="customSwitches2" />
                    <label className="custom-control-label" htmlFor="customSwitches2">Slå på for endre til pakkepriser</label>
                    </div>) : (<br/>)}
        
                        {pakkepriserAut ? (
                            <Table striped bordered hover className="price-table">
                            <thead>
                                <tr>
                                <th>Tjeneste</th>
                                <th>Pris</th>
                                
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td><strong>Full Pakke automat</strong></td>
                                <td></td>
                                
                                </tr>
                                <tr>
                                <td>8 kjøretimer à kr. {priser.AutKjoretime}</td>
                                <td>{priser.AutKjoretime * 8} kr</td>
                                
                                </tr>
                                <tr>
                                <td>2 Trinnvurderinger à kr. {priser.AutTrinnvurdering}</td>
                                <td>{priser.AutTrinnvurdering * 2} kr</td>
                                </tr>
                                <tr>
                                <td>Sikkerhetskurs på øvingsbane eksl. baneleie</td>
                                <td>{priser.AutSikkerhetskursBane} kr</td>
                                </tr>
                                <tr>
                                <td>Sikkerhetskurs på vei</td>
                                <td>{priser.AutSikkerhetskursVei} kr</td>
                                </tr>
                                <tr>
                                <td>Førerprøve</td>
                                <td>{priser.Forerprove} kr</td>
                                </tr>
                                <tr>
                                <td><strong>Pakkepris (Du sparer kr. {(priser.AutKjoretime * 8) + (priser.AutTrinnvurdering * 2) + priser.AutSikkerhetskursBane + priser.Forerprove + priser.AutSikkerhetskursVei - priser.FullPakkeAut},-)</strong></td>
                                <td><strong>{priser.FullPakkeAut} kr</strong></td>
                                </tr>
                                <tr>
                                <td></td>
                                <td></td>
                                </tr>
                                <tr>
                                <td><strong>Obligatorisk pakke automat</strong></td>
                                <td></td>
                                </tr>
                                <tr>
                                <td>2 Trinnvurderinger à kr. {priser.AutTrinnvurdering}</td>
                                <td>{priser.AutTrinnvurdering * 2} kr</td>
                                </tr>
                                <tr>
                                <td>Sikkerhetskurs på vei</td>
                                <td>{priser.AutSikkerhetskursVei} kr</td>
                                </tr>
                                <tr>
                                <td>Sikkerhetskurs på øvingsbane eksl. baneleie</td>
                                <td>{priser.AutSikkerhetskursBane} kr</td>
                                </tr>
                                <tr>
                                <td><strong>Pakkepris (du sparer kr. {(priser.AutTrinnvurdering * 2) + priser.AutSikkerhetskursVei + priser.AutSikkerhetskursBane - priser.ObligatoriskPakkeAut},-)</strong></td>
                                <td><strong>{priser.ObligatoriskPakkeAut} kr</strong></td>
                                </tr>
                                <tr>
                                <td></td>
                                <td></td>
                                </tr>
                                <tr>
                                <td><strong>Kjøretimepakke automat</strong></td>
                                <td></td>
                                </tr>
                                <tr>
                                <td>10 kjøretimer à kr {priser.AutKjoretime}</td>
                                <td>{priser.AutKjoretime * 10} kr</td>
                                </tr>
                                <tr>
                                <td><strong>Pakkepris (du sparer kr. {priser.AutKjoretime * 10 - priser.KjoretimePakkeAut},-)</strong></td>
                                <td><strong>{priser.KjoretimePakkeAut} kr</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                        )
                        : 
                table === 'be' ? (
                    <Table striped bordered hover className="price-table">
                        <thead>
                            <tr>
                            <th>Tjeneste</th>
                            <th>Pris</th>
                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>Kjøretime {eveningRate && ( '(Kveld)')}</td>
                            <td>{eveningRate ? (`${priser.BEKjoretime + eveningRatePrice} kr`) : (`${priser.BEKjoretime} kr`)}</td>
                            
                            </tr>
                            <tr>
                            <td>Førerprøve</td>
                            <td>{priser.BEForerprove} kr</td>
                            </tr>
                            <tr>
                            <td>Obligatorisk trinnvurdering trinn 2</td>
                            <td>{priser.BETrinnvurdering1} kr</td>
                            </tr>
                            <tr>
                            <td>Obligatorisk trinnvurdering trinn 3</td>
                            <td>{priser.BETrinnvurdering2} kr</td>
                            </tr>
                            <tr>
                            <td>Obligatorisk lastesikring trinn 4</td>
                            <td>{priser.BELastesikring} kr</td>
                            </tr>
                            <tr>
                            <td>Obligatorisk sikkerhetskurs på veg</td>
                            <td>{priser.BESikkerhetskurs} kr</td>
                            </tr>
                            <tr>
                            <td><strong>Gebyr Statens Vegvesen trafikkstasjon</strong></td>
                            <td></td>
                            </tr>
                            <tr>
                            <td>Praktisk prøve</td>
                            <td>{priser.BEPraktiskProve} kr</td>
                            </tr>
                            <tr>
                            <td>Utstedelse av førerkort</td>
                            <td>{priser.Forerkort} kr</td>
                            </tr>
                            <tr>
                            <td>Bilde</td>
                            <td>{priser.Bilde} kr</td>
                            </tr>
                        </tbody>
                    </Table>
                ) :
                table === 'b' ? (
                    <Table striped bordered hover className="price-table">
                        <thead>
                            <tr>
                            <th>Tjeneste</th>
                            <th>Pris</th>
                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>Kjøretime {eveningRate && ( '(Kveld)')}</td>
                            <td>{eveningRate ? (`${priser.Kjoretime + eveningRatePrice} kr`) : (`${priser.Kjoretime} kr`)}</td>
                            
                            </tr>
                            <tr>
                            <td>Trinnvurdering {eveningRate && ( '(Kveld)')}</td>
                            <td>{eveningRate ? (`${priser.Trinnvurdering + eveningRatePrice} kr`) : (`${priser.Trinnvurdering} kr`)}</td>
                            
                            </tr>
                            <tr>
                            <td>Sikkerhetskurs på øvingsbane</td>
                            <td>{priser.SikkerhetskursBane} kr</td>
                            </tr>
                            <tr>
                            <td>Sikkerhetskurs på vei</td>
                            <td>{priser.SikkerhetskursVei} kr</td>
                            </tr>
                            <tr>
                            <td>Førerprøve</td>
                            <td>{priser.Forerprove} kr</td>
                            </tr>
                            <tr>
                            <td>NAF Øvingsbane baneleie</td>
                            <td>{priser.NAFBaneleie} kr</td>
                            </tr>
                            <tr>
                            <td><strong>Statens Vegvesen trafikkstasjon</strong></td>
                            <td></td>
                            </tr>
                            <tr>
                            <td>Teoriprøve</td>
                            <td>{priser.Teoriprove} kr</td>
                            </tr>
                            <tr>
                            <td>Praktisk prøve</td>
                            <td>{priser.PraktiskProve} kr</td>
                            </tr>
                            <tr>
                            <td>Utstedelse av førerkort</td>
                            <td>{priser.Forerkort} kr</td>
                            </tr>
                            <tr>
                            <td>Bilde</td>
                            <td>{priser.Bilde} kr</td>
                            </tr>
                        </tbody>
                    </Table>
                ) : 
                table === 'baut' ? (
                    <Table striped bordered hover className="price-table">
                        <thead>
                            <tr>
                            <th>Tjeneste</th>
                            <th>Pris</th>
                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>Kjøretime automat {eveningRate && ( '(Kveld)')}</td>
                            <td>{eveningRate ? (`${priser.AutKjoretime + eveningRatePrice} kr`) : (`${priser.AutKjoretime} kr`)}</td>
                            
                            </tr>
                            <tr>
                            <td>Trinnvurdering {eveningRate && ( '(Kveld)')}</td>
                            <td>{eveningRate ? (`${priser.AutTrinnvurdering + eveningRatePrice} kr`) : (`${priser.AutTrinnvurdering} kr`)}</td>
                            
                            </tr>
                            <tr>
                            <td>Sikkerhetskurs på øvingsbane</td>
                            <td>{priser.AutSikkerhetskursBane} kr</td>
                            </tr>
                            <tr>
                            <td>Sikkerhetskurs på vei</td>
                            <td>{priser.AutSikkerhetskursVei} kr</td>
                            </tr>
                            <tr>
                            <td>Førerprøve</td>
                            <td>{priser.AutForerprove} kr</td>
                            </tr>
                            <tr>
                            <td>NAF Øvingsbane baneleie</td>
                            <td>{priser.AutNAFBaneleie} kr</td>
                            </tr>
                            <tr>
                            <td><strong>Statens Vegvesen trafikkstasjon</strong></td>
                            <td></td>
                            </tr>
                            <tr>
                            <td>Teoriprøve</td>
                            <td>{priser.Teoriprove} kr</td>
                            </tr>
                            <tr>
                            <td>Praktisk prøve</td>
                            <td>{priser.PraktiskProve} kr</td>
                            </tr>
                            <tr>
                            <td>Utstedelse av førerkort</td>
                            <td>{priser.Forerkort} kr</td>
                            </tr>
                            <tr>
                            <td>Bilde</td>
                            <td>{priser.Bilde} kr</td>
                            </tr>
                        </tbody>
                    </Table>
                ) :
                    (
                    <Table striped bordered hover className="price-table">
                        <thead>
                            <tr>
                            <th>Tjeneste</th>
                            <th>Pris</th>
                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>Trafikalt grunnkurs inkl. førstehjelp {props.isKampanje && (<i className="kampanje-tekst">KAMPANJE!</i>) }</td>
                <td>{props.isKampanje ? (<i class="kampanje-tekst">{props.tkgKampanje} kr </i>) : (`${props.tkgPris} kr` )}</td>
                            
                            </tr>
                            <tr>
                            <td>Trafikant i mørket</td>
                            <td>{priser.Morkekjoring} kr</td>
                            
                            </tr>
                            <tr>
                            <td>Førstehjelp</td>
                            <td>{priser.Forstehjelp} kr</td>
                            </tr>
                            <tr>
                            <td><strong>Totalpris inkl. førstehjelp og Trafikant i mørket {priser.TGKTotalprisRabattKr > 0 ? (`(Du sparer Kr. ${priser.TGKTotalprisRabattKr},-)`): ""}</strong></td>
                            <td><strong>{props.isKampanje ? (
                                (priser.TKG - priser.TKG_Rabatt_Kr) + priser.Morkekjoring - priser.TGKTotalprisRabattKr): (
                                 priser.TKG + priser.Morkekjoring - priser.TGKTotalprisRabattKr
                            )} kr</strong></td>
                            </tr>
                        </tbody>
                    </Table>
                )
                }
            </div>
        </Layout>
    )
    
}

export default Pricepage
