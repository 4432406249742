import React from 'react';
import {Card, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const TkgKurs = (props) => {
    return (
        <div className="container flex p-5 cards-container">
            <Card style={{ width: '22rem' }} className="mb-2 ml-4 bg-light animate__animated animate__fadeIn">
              <Card.Header className="bg-primary">Neste Kurs</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                    Neste trafikalt grunnkurs holdes:<br/>
                    <strong>{props.tgkDateFormated} klokken {props.tgkTime}</strong>.
                </Card.Text>
                <Card.Text>
                Kurset holdes i våre trivelige lokaler på Abildsø.
                </Card.Text>
              </Card.Body>
            </Card>  
            <Card style={{ width: '22rem' }} className="ml-4 mb-2 bg-light animate__animated animate__fadeIn animate__delay-1s">
              <Card.Header className="bg-primary">Påmelding og pris</Card.Header>
              <Card.Body className="tab-content">
              <Card.Text>
                    Pris for trafikalt grunnkurs er: {props.tkgPris}<br/>
                    Gå til prissiden for mer informasjon og flere priser: 
                </Card.Text>
                <Link to="/priser"><Button variant="danger">Priser</Button></Link>
                <Card.Text><br/>
                    Meld deg på kurs via våre kursider:
                </Card.Text>
                <Link to="/vare-kurs"><Button variant="danger">Påmelding</Button></Link>
              </Card.Body>
            </Card> 
        </div>
    )
}

export default TkgKurs