import React, {useState, useContext} from 'react'
import Layout from './Layout'
import KlassebTabs from './klasseb/KlassebTabs';
import KlassebTrinn from './klasseb/KlassebTrinn';
import KlassebTrinn2 from './klasseb/KlassebTrinn2';
import KlassebTrinn3 from './klasseb/KlassebTrinn3';
import KlassebTrinn4 from './klasseb/KlassebTrinn4';

import StateContext from '../StateContext';

const KlassebPage = (props) => {

    const appContext = useContext(StateContext);
    const textInfo = appContext.kontaktInfo;

    const [tab, setTab] = useState(1);
    const [tabCSS, setTabCss] = useState('tab-1');
  
    const setKlassebTab = (value) => {
        setTab(value);
        setTabCss(`tab-${value}`);
    }

    function createKlassebInfo() {
        return {__html: textInfo.KlassebInfo};
      }

    return (
        <Layout>
            <div className="container heading flex">
                <h3 className="display-5">Klasse B</h3>
                <i className="fas fa-car heading-icon"></i>
            </div>

            <div className="container page-content">
                <h3 className="display-6"><strong>{textInfo.KlassebHeading}</strong></h3>
                    <p className="lead" dangerouslySetInnerHTML={createKlassebInfo()}></p>
                    <br/>
                <h3 className="display-6">
                    <strong>Opplæringen er delt opp i 4 ulike deler:</strong>
                </h3>
            </div>
                <KlassebTabs setKlassebTab={(value) => {setKlassebTab(value)}}/>
                <section className={`container-fluid ${tabCSS}`}>
                    {tab === 4 ? (<KlassebTrinn4 /> ) :  tab === 3 ? (<KlassebTrinn3 /> ) : tab === 2 ? (<KlassebTrinn2 />) : (<KlassebTrinn TkgPris={props.tkgPris} tkgRabatt={props.tkgRabatt} />) }
                </section>
            
        </Layout>
    )
}

export default KlassebPage
