import React from 'react';
import {Link} from 'react-router-dom';

const Navigation = () => {
    return (
        <div className="container-fluid">
            
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                
                    <ul className="navbar-nav container">
                        <li className="nav-item pr-2">
                            <Link to="/" className="nav-link hvr-underline-reveal">Forside</Link>
                        </li>
                        <li className="dropdown">
                            <div className="dropbtn">Tjenester
                                <i className="fa fa-caret-down"></i>
                            </div>
                            <div className="dropdown-content">
                                <Link className="nav-link hvr-underline-reveal" to="/klasse-b">Klasse B</Link>
                                <Link className="nav-link hvr-underline-reveal" to="/klasse-be">Klasse BE/B96</Link>
                                <Link className="nav-link hvr-underline-reveal" to="/trafikalt-grunnkurs">Trafikalt Grunnkurs</Link>
                                <Link className="nav-link hvr-underline-reveal" to="/vare-kurs">Våre Kurs</Link>
                            </div>
                        </li>
                        <li className="nav-item pr-2">
                            <Link className="nav-link hvr-underline-reveal" to="/priser">Priser</Link>
                        </li>
                        <li className="nav-item pr-2">
                            <Link className="nav-link hvr-underline-reveal" to="/kontakt">Kontakt Oss</Link>
                        </li>
                        
                    </ul>
            </div>
            </nav>
        </div>
    )
}

export default Navigation
