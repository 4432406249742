import React from 'react';
import {Card, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const Klasseb = () => {
    return (
        <div className="container flex p-5 cards-container">
            <Card bg="danger" style={{ width: '22rem'}} className="ml-4 mt-2 animate__animated animate__fadeIn">
            {/* <Card.Img variant="top" src={be} /> */}
              <Card.Header>Førerkort</Card.Header>
              <Card.Body>
                <Card.Text>
                  Vi ønsker å gi deg den best mulige trafikkopplæringen og tilpasser opplæringen til hver enkelt elevs forutsettninger og behov. 
                </Card.Text>
                <Card.Text>
                  Opplæringen er delt opp i 4 ulike deler som starter med å gjennomføre et trafikkalt grunnkurs.
                </Card.Text>
                <Link className="align-center" to="/klasse-b"><Button  variant="light">Les mer</Button></Link>
              </Card.Body>
            </Card>  

            <Card bg="danger" style={{ width: '22rem' }} className="ml-4 mt-2 animate__animated animate__fadeIn animate__delay-1s">
            <Card.Header>Kjøretimer</Card.Header>
            <Card.Body>
                <Card.Text>
                En kjøretime varer i 45 minutter og går fra tiden du setter deg inn i bilen til du forlater den igjen.
                </Card.Text>
                <Card.Text>
                Vi prøver å hente og bringe i nærmiljøet, men noen ganger er det lurt å møtes andre steder for å utnytte timen bedre med hensyn til hva det skal øves på.
                </Card.Text>
            </Card.Body>
            </Card> 

            <Card bg="danger" style={{ width: '22rem' }} className="ml-4 mt-2 animate__animated animate__fadeIn animate__delay-2s">
            <Card.Header>Start opplæringen</Card.Header>
            <Card.Body>
            <Card.Text>
                Prisliste for kjøreopplæring finner du her: <br/>
                <Link to="/priser"><Button variant="light">Priser</Button> <br/></Link>
            </Card.Text>
            <Card.Text>
                Ta kontakt for mer informasjon eller bestilling av time: <br/>
                <Link to="/kontakt"><Button variant="light">Kontakt oss</Button></Link>
            </Card.Text>
            </Card.Body>
            </Card>
            
        </div>
    )
}

export default Klasseb
