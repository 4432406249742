import React from 'react';
import {Card, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import be from '../be.png';

const KlasseBE = () => {
    return ( 
        <div className="container flex p-5 cards-container">
            <Card bg="danger" style={{ width: '22rem' }} className="ml-4 mt-2 animate__animated animate__fadeIn">
            {/* <Card.Img variant="top" src={be} /> */}
              <Card.Header>Tung tilhenger</Card.Header>
              <Card.Body>
                <Card.Text>
                  Vi utfører opplæring i Klase BE som lar deg kjøre bil med tung tilhenger inntil 4250kg totalvekt. 
                </Card.Text>
                <Card.Text>
                Dette inkluderer campingvogn, hestehenger og båthenger. 
                </Card.Text>
                <Link to="/klasse-be"><Button variant="light">Les mer</Button></Link>
              </Card.Body>
            </Card>  

            <Card bg="danger" style={{ width: '22rem' }} className="ml-4 mt-2 animate__animated animate__fadeIn animate__delay-1s">
            <Card.Header>Start opplæringen</Card.Header>
            <Card.Body>
            <Card.Text>
                Prisliste for kjøreopplæring finner du her: <br/>
                <Link to="/priser"><Button variant="light">Priser</Button> <br/></Link> <br/>
            </Card.Text>
            <Card.Text>
                Ta kontakt for mer informasjon eller bestilling av time: <br/>
                <Link to="/kontakt"><Button variant="light">Kontakt oss</Button></Link>
            </Card.Text>
            </Card.Body>
            </Card>
            
            {/* <img className="ml-4" src={be} alt="" /> */}
            <Card bg="danger" style={{ width: '22rem' }} className="ml-4 mt-2 animate__animated animate__fadeIn animate__delay-2s">
              
            <Card.Img variant="top" src={be} />
              <Card.Body className="image-card-body">
              Før du kan ta BE trenger du førerkort klasse B
              </Card.Body>
            </Card>  
        </div>
    )
}

export default KlasseBE
