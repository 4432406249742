import React, {useState, useContext} from 'react';
import {Alert} from 'react-bootstrap';
import Layout from './Layout';
import Paper from '@material-ui/core/Paper';
import Terje from '../terje.png';

import StateContext from '../StateContext'

const Kontakt = () => {

    const [formMessage, setFormMessage] = useState(false);
    const [navn, setNavn] = useState();
    const [epost, setEpost] = useState();
    const [melding, setMelding] = useState();

    const appContext = useContext(StateContext);
    const kontaktInfo = appContext.kontaktInfo;

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }

    const handleForm = (e) => {
        // e.preventDefault();

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", navn, epost, melding})
          })
            .then(() => setFormMessage(true))
            .catch(error => console.log(error));
    
          e.preventDefault();

        // setFormMessage(true)
        setTimeout(() => {
            setFormMessage(false);
        }, 3500);
    };

    function createKontaktInfo() {
        return {__html: kontaktInfo.KontaktInfo};
      }

    return (
        
        <Layout>
            <div className="container heading flex">
                <h3 className="display-5">Kontakt Oss</h3>
                <i className="fas fa-mail-bulk heading-icon"></i>
            </div>

            <div className="container page-content">
                <div className="row info-row">
                    <div className="col col-contact-info">
                        <a href={`tel:${kontaktInfo.Phone}`}>
                            <Paper elevation={3} className="info-box animate__fadeIn animate__delay-1s">
                            <i className="fas fa-phone-square-alt"></i><br/>
                            <strong>Telefon:</strong><br/>
                            <span className="mainColor">{kontaktInfo.Phone}</span>
                            </Paper>
                        </a>
                    </div>
                    <div className="col col-contact-info">
                        <a href={`mailto:${kontaktInfo.Email}`}>
                            <Paper elevation={3} className="info-box">
                            <i className="far fa-envelope"></i><br/>
                            <strong>Epost:</strong><br/>
                            <span className="mainColor">{kontaktInfo.Email}</span>
                            </Paper>
                        </a>
                    </div>
                    <div className="col col-contact-info contact-info-address">
                        <a href="https://goo.gl/maps/EaWzkvV6QFePobA37" target="new">
                            <Paper elevation={3} className="info-box">
                            <i className="fas fa-map-marker-alt"></i><br/>
                            <strong>Adresse:</strong><br/>
                            <span className="mainColor">{kontaktInfo.Address}</span>
                            </Paper>
                        </a>
                    </div>
                    <div className="col col-contact-info d-none d-lg-block">
                    <a href={`tel:${kontaktInfo.TerjeMobil}`}>
                            <Paper elevation={3} className="info-box">
                            <i className="fas fa-mobile-alt"></i><br/>
                            <strong>Terje Mobil:</strong><br/>
                            <span className="mainColor">{kontaktInfo.TerjeMobil}</span>
                            </Paper>
                        </a>
                    </div>
                </div>
                
                <div className="row mt-5 mb-5">
                    <div className="col animate__fadeIn animate__delay-2s">
                        <p className="lead" dangerouslySetInnerHTML={createKontaktInfo()}></p>
                        {/* <strong>Du finner oss på Abildsø.</strong> Vi har lokaler sentralt på Abildsø i samme bygget som Bruktbilsenteret AS i <a href="https://goo.gl/maps/EaWzkvV6QFePobA37" target="new">Enebakkveien 275</a>.<br/><br/>

                        Buss 70 og 79 stopper like ved. Du kan eventuelt ta T-bane til Skullerud. Derfra er det ca 10 minutter å gå. <br/><br/> */}
                        {/* {kontaktInfo.KontaktInfo} */}
                        <br/>
                        <p className="lead">
                        Trykk <a href="https://goo.gl/maps/EaWzkvV6QFePobA37" target="new">her</a> eller på kart og adresselenker på siden for å se fullt kart.
                        </p>
                    </div>
                    <div className="col">

                        {formMessage ? (
                        <Alert style={{color: '#000'}} variant="success">Din melding er sendt!</Alert>
                        ) : (
                        <form onSubmit={handleForm} name="contact" className="contact-form animate__fadeIn animate__delay-3s">
                            <input type="hidden" name="form-name" value="contact" />
                            <h2>Send oss en melding</h2>
                            <p type="Navn:"><input type="text" name="navn" value={navn} onChange={(e) => setNavn(e.target.value)} placeholder="Ditt fulle navn her.." aria-label="Ditt Navn skrives her"></input></p>
                            <p type="Epost:"><input type="email" name="epost" value={epost} onChange={(e) => setEpost(e.target.value)} placeholder="Din epost så vi kan kontakte deg.." aria-label="Din epost skrives her"></input></p>
                            <p type="Melding:"><input type="text" name="melding" value={melding} onChange={(e) => setMelding(e.target.value)} placeholder="Din melding.." aria-label="Skriv inn din melding her"></input></p>
                            <div data-netlify-recaptcha="true"></div>
                            <button type="submit">Send Melding</button>
                        </form>
                        )}

                        
                        
                    </div>
                    
                </div>
                
            </div>

            

                <section className="container-fluid teacher-section">
                    <div className="container">
                        <div className="row">
                            <div className="col teacher-content flex animate__animated animate__fadeIn">
                                <img src={Terje} style={{maxHeight: '340px'}} alt="Trafikklærer Terje" />
                                <div className="teacher-text-container flex">
                                    <h3 className="display-6 teacher-heading animate__animated animate__fadeIn animate__delay-1s">{kontaktInfo.KontaktTerjeHeading}</h3>
                                    <div className="teacher-text animate__animated animate__fadeIn animate__delay-2s">
                                        <p className="lead">{kontaktInfo.KontaktTerjeInfo}</p>
                                        <blockquote>{kontaktInfo.KontaktTerjeQuote}</blockquote>
                                    </div>
                                    <div className="flex animate__animated animate__fadeIn animate__delay-3s" style={{justifyContent: 'flex-end'}}>
                                        <a href={`tel:${kontaktInfo.TerjeMobil}`}><button type="button" className="btn btn-danger btn-lg teacher-button">{kontaktInfo.TerjeMobil}</button></a>
                                    </div>
                                   
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
                
                {/* <div className="chat"><i class="fab fa-facebook-messenger"></i> Chat med oss</div> */}
        </Layout>
    )
}

export default Kontakt
