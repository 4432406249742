import React from 'react';
import Header from './Header';
import Navbar from './Navigation';
import Footer from './Footer';
import './layout.css';


const Layout = (props) => {
    return ( <>
        <Header />
        <Navbar />
            {props.children}
        <Footer />
    </>)
}

export default Layout
