import React from 'react';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const Legacy = (props) => {
    return ( <>
        <section className="container-fluid main-content">
            <div className="container">
                <div className="main-content">
                    <div className="car-absolute animate__animated animate__fadeInLeftBig animate__delay-1s"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="jumbotron animate__animated animate__fadeIn">
                                <h1 className="mainColor mainHeader">{props.infoHeading}</h1>
                                    <p className="lead">Proff Trafikkskole AS er trafikkskolen på Abildsø. {` `}
                                    {/* <a href="https://w2.brreg.no/enhet/sok/detalj.jsp;jsessionid=KAIi8I_5LdX_mm1qkkjZHCWRos1BxRkolmGXQUA8htqDlGALIvYv!-1232853535!-671813275?orgnr=970013458" target="New" title="Gå til Brreg.no">Nordvold`s Trafikkskole</a>.  */}
                                    Her er et profesjonelt og trivelig miljø med utvidet kompetanse som tilbyr skreddersydd opplæring for hver enkelt elevs forutsetninger og behov.</p>
                                    <hr className="my-4"></hr>
                                    <p>Vi har lokaler sentralt på Abildsø i samme bygget som Bruktbilsenteret AS i Enebakkveien 275. Buss 70 og 79 stopper like ved. Du kan eventuelt ta T-bane til Skullerud.</p>
                                    <Link to="/kontakt" className="btn btn-danger btn-lg" role="button"><i className="fas fa-phone-alt"></i> Ta kontakt</Link>
                                </div>
                            </div>    
                            
                            <div className="col tkg animate__animated animate__fadeIn">
                                <div className="tkg-txt animate__animated animate__pulse animate__delay-1s">
                                    {props.tgkDateBackend < props.dateNowFormated ? ( <>
                                        
                                        
                                        <h3>Ingen kurs er satt opp!</h3> <br/>
                                        <h4>Sjekk kursiden, trykk "Påmelding"</h4>
            
                                        </>
                                    ) : (
                                        <h3>Neste Trafikalt Gunnkurs: <br/>
                                        {props.tgkDateFormated} <br/>
                                        Klokken {props.tgkTime}
                                    </h3>    
                                    )}
                                    <Link to="/vare-kurs"><Button variant="outline-light">Påmelding</Button></Link>
                                </div>
                            </div>
                        </div>
                        {props.newsActive && props.newsExpiration >= props.dateNowFormated ? (
                            <div className="row mb-2">
                                <div className="col">
                                    <div className="news-box animate__animated animate__fadeIn animate__delay-2s">
                                        <div className="display-6 border-bottom border-secondary">{props.newsHeading}</div><br/>
                                            <p>{props.newsText}</p>
                                    </div>
                                </div>
                            </div>
                        ): ""}
                        <br/>
                    </div>
                </div>    
            </div>  
          </section>
                </>
    )
}

export default Legacy
