import React from 'react'
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import TkgImg from '../../tkg-rom.png';

const Modern = (props) => {

    return ( <>
        <section className="container-fluid main-content modern-bg">
            <div className="container">
                <div className="modern-content flex">
                    
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="modern-textbox animate__animated animate__fadeIn">
                                    <h1 className="mainColor mainHeader">{props.infoHeading}</h1>
                                    <p className="lead">Proff Trafikkskole AS er trafikkskolen på Abildsø. {` `}
                                    {/* <a href="https://w2.brreg.no/enhet/sok/detalj.jsp;jsessionid=KAIi8I_5LdX_mm1qkkjZHCWRos1BxRkolmGXQUA8htqDlGALIvYv!-1232853535!-671813275?orgnr=970013458" target="New" title="Gå til Brreg.no">Nordvold`s Trafikkskole</a>.  */}
                                    Her er et profesjonelt og trivelig miljø med utvidet kompetanse som tilbyr skreddersydd opplæring for hver enkelt elevs forutsetninger og behov.</p>
                                    <hr className="my-4"></hr>
                                    <p>Vi har lokaler sentralt på Abildsø i samme bygget som Bruktbilsenteret AS i Enebakkveien 275. Buss 70 og 79 stopper like ved. Du kan eventuelt ta T-bane til Skullerud.</p>
                                    <Link to="/kontakt" className="btn btn-danger btn-lg" role="button"><i className="fas fa-phone-alt"></i> Ta kontakt</Link>
                                </div>
                            </div>    
                            
                            <div className="col"> 
                                <div className="modern-textbox animate__animated animate__fadeIn animate__delay-1s">
                                    <div className="tkg-image">
                                    <img src={TkgImg} alt="Trafikalt Grunkurs i klasserom" style={{ width: '100%' }}/>
                                    </div>
                                    <div className="tkg-text">
                                        <div className="display-6">Velkommen til Trafikalt Grunnkurs</div>
                                                
                                        {props.isKampanje && props.tkgKampanjeSlutt >= props.dateNow && props.tgkDateBackend >= props.dateNowFormated ? ( <>
                                            <p>Neste kurs: <strong>{props.tgkDateFormated} klokken {props.tgkTime}</strong>.</p>
                                            <div className="flex-end">
                                            <h3 className="kampanje-tekst"><strong>Kampanje! {props.tkgPris - props.tkgRabatt}kr</strong></h3>
                                                <Link to="/vare-kurs"><Button className="btn btn-danger btn-lg" variant="danger">Påmelding</Button></Link>
                                            </div>
                                            </>) : props.tgkDateBackend >= props.dateNowFormated ? (<>
                                                <p>Neste kurs: <strong>{props.tgkDateFormated} Klokken {props.tgkTime}</strong>.</p> 
                                                <div className="align-button">
                                                <Link to="/vare-kurs"><Button className="btn btn-danger btn-lg" variant="danger">Påmelding</Button></Link>
                                            </div>
                                            </>) : ( <>
                                                <p><strong>Ingen kurs er satt opp! Trykk Påmelding for å sjekke kursiden</strong>.</p>
                                                <div className="align-button">
                                                <Link to="/vare-kurs"><Button className="btn btn-danger btn-lg" variant="danger">Påmelding</Button></Link>
                                                </div>
                                            </>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {props.newsActive && props.newsExpiration >= props.dateNowFormated ? (
                            <div className="row">
                                <div className="col">
                                    <div className="news-box animate__animated animate__fadeIn animate__delay-2s">
                                        <div className="display-6 border-bottom border-secondary">{props.newsHeading}</div><br/>
                                            <p>{props.newsText}</p>
                                    </div>
                                </div>
                            </div>
                        ): ""}
                    </div>
                </div>    
            </div>  
          </section>

    </>
    )
}

export default Modern
