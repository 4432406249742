import React from 'react';
import {Card, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const KlassebTrinn4 = () => {
    return (
        <div className="container flex p-5 animate__animated animate__fadeIn cards-container">
            <Card style={{ width: '42rem' }} className="ml-4 mt-2 bg-light">
              <Card.Header className="bg-primary">Avsluttende Opplæring</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                 Etter å ha gjennomført trinn 4 skal eleven ha den kompetansen som er nødvendig for å kunne kjøre bil i samsvar med hovedmålet.
                </Card.Text>
                <Card.Text>
                 Eleven skal ha utviklet vilje til å ta ansvar, til å ta forholdsregler og til å samarbeide i trafikken.
                </Card.Text>
              </Card.Body>
            </Card>  

            <Card style={{ width: '24rem' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn animate__delay-1s">
            <Card.Header className="bg-primary">Sikkerhetskurs</Card.Header>
            <Card.Body className="tab-content">
                <Card.Text>
                Eleven skal gjennomføre sikkerhetskurs på veg i samsvar med § 11-8 og
                § 11-9 i forskrift om trafikkopplæring og førerprøve, og i tillegg ha tilstrekkelig øving.
                </Card.Text>
                <Card.Text>
                Ta kontakt med oss for mer informasjon.
                </Card.Text>
                <Link to="/kontakt"><Button variant="danger">Kontakt Oss</Button></Link>

            </Card.Body>
            </Card> 
        </div>
    )
}

export default KlassebTrinn4