import React from 'react';
import {Card, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const KlassebeTrinn4 = () => {
    return (
        <div className="container flex p-5 animate__animated animate__fadeIn cards-container">
            <Card style={{ width: '22rem' }} className="ml-4 mt-2 bg-light">
              <Card.Header className="bg-primary">Obligatoriske kurs</Card.Header>
              <Card.Body className="tab-content">
                <Card.Text>
                 I trinn 4 er det et obligatorisk lastsikringskurs på 2 timer og obligatorisk sikkerhetskurs på veg, 3 timer.
                </Card.Text>
                <Card.Text>
                 Hvis nivået er godt nok har man nå ervervet førerkort klasse B96
                </Card.Text>
              </Card.Body>
            </Card>  

            <Card style={{ width: '24rem' }} className="ml-4 mt-2 bg-light animate__animated animate__fadeIn animate__delay-1s">
            <Card.Header className="bg-primary">Førerprøve</Card.Header>
            <Card.Body className="tab-content">
                <Card.Text>
                For klasse BE så må førerprøve hos Statens Vegvesen bestås.
                </Card.Text>
                <Card.Text>
                Ta kontakt med oss for mer informasjon.
                </Card.Text>
                <Link to="/kontakt"><Button variant="danger">Kontakt Oss</Button></Link>
            </Card.Body>
            </Card> 
        </div>
    )
}

export default KlassebeTrinn4