import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import logo from '../logo.png';

import StateContext from '../StateContext';

const Header = () => {

    const appContext = useContext(StateContext);
    const kontaktInfo = appContext.kontaktInfo;

    return (
        <div id="top" className="container-fluid">
            <span className="proff-top-line"></span>
            <header className="container header">
                <Link to="/"><img src={logo} className="App-logo" alt="logo" /></Link>
                <a className="hide-from-532px" href="https://www.google.com/maps/place/Enebakkveien+275,+1187+Oslo/@59.8786532,10.8245108,17z/data=!3m1!4b1!4m5!3m4!1s0x46416f411ac987cb:0x2c5edc19d9b63a1f!8m2!3d59.8786505!4d10.8267048" target="new"><div><i className="fas fa-map-marker-alt header-icon"></i> {kontaktInfo.Address}</div></a>
                <a href={`tel:+47${kontaktInfo.Phone}`}><div><i className="fas fa-phone-alt header-icon hide-from-319px"></i> + 47 {kontaktInfo.Phone}</div></a>
                <a href="https://tabselev.no/login/student" target="new" title="Gå til tabselev.no"><button type="button" className="btn btn-danger btn-lg btn-elev-login">Elev Login</button></a>
            </header>
        </div>
    )
}

export default Header
