import React, {useState} from 'react';
import Legacy from './main-sub-components/Legacy';
import Modern from './main-sub-components/Modern';

const Main = (props) => {

    // Props from App.js 
    const tkg = props.tkg;
    const frontpage = props.frontpage;

    // State for the alertbox
    const [alertactive, setAlertActive] = useState('undef');

    const tkgKampanjeSlutt = new Date(props.tkgKampanjeSlutt);
    const newsExpiration = frontpage.NewsExpiration;
    const tgkDateBackend = tkg.Dato;
    const dateNow = new Date();

    const handleAlert = () => {
        setAlertActive('false');
    }

    return (
        <>
            {frontpage.AlertActive && alertactive !== 'false' ? (
            <div className={`alert-box`}>
                <div className={`alert ${frontpage.AlertType}`}><button aria-hidden="true" onClick={() => handleAlert()} data-dismiss="alert" className={`close close-${frontpage.AlertType}`} type="button">×</button>{frontpage.AlertText}</div>
            </div>
            ): '' }
            {frontpage.Side_Type === 'Gammel' ? (
                <Legacy tgkDate={props.tgkDate} tgkTime={props.tgkTime} tgkDateFormated={props.tgkDateFormated}  isKampanje={props.isKampanje} tkgKampanjeSlutt={tkgKampanjeSlutt} tkgRabatt={props.tkgRabatt} 
                        newsActive={frontpage.NewsActive} newsHeading={frontpage.NewsHeading} newsText={frontpage.NewsText} newsExpiration={newsExpiration} dateNowFormated={props.dateNowFormated} 
                        tgkDateBackend={tgkDateBackend} infoHeading={frontpage.InfoHeading} newsDate={frontpage.NewsExpiration} dateNow={dateNow} />
            ) : (
                <Modern tgkDate={props.tgkDate} tgkTime={props.tgkTime} tgkDateFormated={props.tgkDateFormated}  isKampanje={props.isKampanje} tkgKampanjeSlutt={tkgKampanjeSlutt} tkgRabatt={props.tkgRabatt} tkgPris={props.tkgPris}
                        newsActive={frontpage.NewsActive} newsHeading={frontpage.NewsHeading} newsText={frontpage.NewsText} newsExpiration={newsExpiration} dateNowFormated={props.dateNowFormated} 
                        tgkDateBackend={tgkDateBackend} infoHeading={frontpage.InfoHeading} newsDate={frontpage.NewsExpiration} dateNow={dateNow}/>
            )}
        </>
    )
}

export default Main
